import { Link, Outlet, createFileRoute } from '@tanstack/react-router'
import clsx from 'clsx'
import type { ReactElement } from 'react'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import {
  aside,
  asideImage,
  asideTitle,
  container,
  main,
  mainImage
} from '~/pages/styles/Login.css'
import { themeClass } from '~/styles/theme.css'
import logoUrl from '/job-explorer.svg?url'
import loginDrawUrl from '/login.svg?url'

const Login = (): ReactElement => {
  return (
    <div className={clsx(themeClass, container)}>
      <main className={main}>
        <div>
          <Link to={'/'}>
            <h1 className={mainImage}>
              <img src={logoUrl} alt="job explorer" />
            </h1>
          </Link>
          <Outlet />
        </div>
      </main>
      <aside className={aside}>
        <h2 className={asideTitle}>
          Accompagner la croissance des professionnels du recrutement
        </h2>
        <img
          src={loginDrawUrl}
          alt="Logo de job explorer"
          className={asideImage}
        />
      </aside>
    </div>
  )
}

export const Route = createFileRoute('/_login')({
  component: Login,
  loader: () => <Loader />
})
