import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { shimmer } from '~/components/Global/Elements/Shimmer/Shimmer.css'

interface ShimmerProps {
  width?: string | number
  height?: string | number
  className?: string
}

export const Shimmer = ({
  width,
  height,
  className
}: ShimmerProps): ReactElement => {
  return (
    <div
      className={clsx(shimmer, className)}
      style={{
        width,
        height
      }}
    />
  )
}
