import {
  faEye,
  faFileAlt,
  faGear,
  faPowerOff,
  faUser
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { type ReactElement, useRef, useState } from 'react'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import type { UserGroup } from '~/types/global'
import { container } from '../../Global/Interactions/Dropdown/Downdown.css'
import { Dropdown } from '../../Global/Interactions/Dropdown/Dropdown'
import {
  functionName,
  groupSwitch,
  groupsLabel,
  item,
  name,
  picture,
  roleSwitcher,
  roleSwitcherActive,
  roleSwitcherTrigger,
  trigger
} from './Menu.css'

export interface MenuProps {
  legacyUrl?: string | null
  isLegacy?: boolean
  onChangeGroup?: (group: UserGroup) => void
  user?: {
    id: number
    name: string
    function: string | null
    picture?: string | null
    availableGroups: UserGroup[]
    notificationCount: number
  }
}

const getIcon = (group: UserGroup): ReactElement | null => {
  switch (group.name) {
    case 'Admin':
      return <FontAwesomeIcon icon={faUser} />

    case 'Téléacteur':
      return <FontAwesomeIcon icon={faFileAlt} />

    case 'Consultant':
      return <FontAwesomeIcon icon={faEye} />

    case 'Chargé de recrutement':
      return <FontAwesomeIcon icon={faEye} />

    case 'Autre':
      return <FontAwesomeIcon icon={faEye} />
  }

  return null
}

export const Menu = ({
  user,
  legacyUrl,
  onChangeGroup
}: MenuProps): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  if (user === undefined) {
    return <Shimmer width={40} height={40} />
  }

  return (
    <div className={container} ref={menuRef}>
      <span onClick={() => setOpen(!isOpen)} className={trigger}>
        <span className={name}>
          {user.name}
          {user.function !== null && user.function !== '' && (
            <span className={functionName}>
              <FontAwesomeIcon icon={faUser} />
              {user.function}
            </span>
          )}
        </span>
        {user.picture !== undefined && (
          <img
            src={user.picture === null ? 'default.png' : user.picture}
            alt=""
            className={picture}
          />
        )}
        {user.picture === undefined && <Shimmer width={40} height={40} />}
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
      <Dropdown
        isOpen={isOpen}
        onclose={() => setOpen(false)}
        dropdownRef={menuRef}
      >
        <div>
          <span className={groupsLabel}>Connecté en tant que</span>
          {user.availableGroups.length > 0 && (
            <div className={groupSwitch}>
              {user.availableGroups.map(
                (group): ReactElement => (
                  <span
                    key={group.id}
                    className={clsx(
                      roleSwitcher,
                      group.active === true
                        ? roleSwitcherActive
                        : roleSwitcherTrigger
                    )}
                    onClick={async () => {
                      if (group.active === false) {
                        onChangeGroup?.(group)
                      }
                    }}
                  >
                    {getIcon(group)} {group.name}
                  </span>
                )
              )}
            </div>
          )}
        </div>
        <a
          href={`${legacyUrl ?? ''}/admin/securite/referentiels`}
          className={item}
        >
          <FontAwesomeIcon icon={faGear} />
          Préférences
        </a>
        <a href={`${legacyUrl ?? ''}/users/logout`} className={item}>
          <FontAwesomeIcon icon={faPowerOff} />
          Déconnexion
        </a>
      </Dropdown>
    </div>
  )
}
