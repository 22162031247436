import {
  type InfiniteData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import type { UserNotificationsLDResponse } from '~/api/queries/user'
import { fetchApiWithToken } from '~/api/utils'
import { toast } from '~/utils'

type ReadNotificationPayload = {
  notificationId: number
}

export const useReadNotificationMutation = ({ userId }: { userId: number }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ notificationId }: ReadNotificationPayload) =>
      fetchApiWithToken({
        method: 'PATCH',
        url: `/v1/notifications/${notificationId}`,
        data: {
          isRead: true
        }
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['user', 'notifications', userId]
      })
  })
}

export const useDeleteNotificationMutation = ({
  userId
}: { userId: number }) => {
  const queryClient = useQueryClient()
  return useMutation<
    boolean,
    Error,
    { notificationId: number },
    { previousNotifications?: InfiniteData<UserNotificationsLDResponse> }
  >({
    mutationFn: ({ notificationId }: ReadNotificationPayload) =>
      fetchApiWithToken({
        method: 'DELETE',
        url: `/v1/notifications/${notificationId}`
      }),
    onMutate: async ({ notificationId }) => {
      await queryClient.cancelQueries({
        queryKey: ['user', 'notifications', userId]
      })
      const previousNotifications = queryClient.getQueryData<
        InfiniteData<UserNotificationsLDResponse, Error>
      >(['user', 'notifications', userId])
      if (previousNotifications) {
        const updatedNotifications = previousNotifications.pages.map(
          (page) => ({
            ...page,
            'hydra:member': page['hydra:member'].filter(
              (notification) => notification.id !== notificationId
            )
          })
        )
        queryClient.setQueryData(['user', 'notifications', userId], {
          pages: updatedNotifications,
          pageParams: previousNotifications.pageParams
        })
      }
      return { previousNotifications }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['user', 'notifications', userId]
      }),
    onError: async (error, variables, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData(
          ['user', 'notifications', userId],
          context.previousNotifications
        )
      }
    }
  })
}
