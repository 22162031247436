import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faExclamation,
  faInfo,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { themeClass } from '~/styles/theme.css'
import {
  toast,
  toastClosingButton,
  toastContent,
  toastIcon,
  toastIconVariants,
  toastMain,
  toastTitle
} from './Toast.css'

export enum ToastType {
  Default = 'default',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

export interface ToastProps {
  title?: string
  content?: string
  type?: ToastType
  onClose?: () => void
}

const getIconByType = (type: ToastType): IconDefinition => {
  switch (type) {
    case ToastType.Success:
      return faCheck
    case ToastType.Warning:
      return faExclamation
    case ToastType.Danger:
      return faExclamation
    default:
      return faInfo
  }
}

export const Toast = ({
  title,
  content,
  type,
  onClose
}: ToastProps): ReactElement => (
  <div className={clsx(themeClass, toast)} role="alert">
    <div
      className={clsx(
        themeClass,
        toastIcon,
        toastIconVariants[type ?? ToastType.Default]
      )}
    >
      <FontAwesomeIcon icon={getIconByType(type ?? ToastType.Default)} />
    </div>
    <div className={clsx(themeClass, toastMain)}>
      {title !== '' && (
        <div className={clsx(themeClass, toastTitle)}>{title}</div>
      )}
      {content !== undefined && (
        <div
          className={clsx(themeClass, toastContent)}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
    <div className={clsx(themeClass, toastClosingButton)} onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  </div>
)
