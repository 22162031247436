import { RouterProvider, createRouter } from '@tanstack/react-router'
import React, { type ReactElement, Suspense, useContext } from 'react'
import { useCurrentUserInfos, useUserTokenRefresh } from '~/api/queries/user'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import { AuthContext } from '~/utils/contexts'
import { routeTree } from './routeTree.gen'
import { themeClass } from './styles/theme.css'

const router = createRouter({
  routeTree,
  context: {
    currentUser: undefined
  }
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const Main = (): ReactElement => {
  const currentUser = useContext(AuthContext)
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} context={{ currentUser }} />
    </Suspense>
  )
}

export const App = (): ReactElement => {
  const { data: userData, isFetching } = useCurrentUserInfos()
  const { data: refreshToken, isError, isFetched } = useUserTokenRefresh()

  if (refreshToken?.token !== undefined) {
    localStorage.setItem('token', refreshToken.token)
  }

  if (isFetched && isError) {
    localStorage.removeItem('id')
    localStorage.removeItem('token')
  }

  if (
    localStorage.getItem('id') !== null &&
    localStorage.getItem('id') !== '' &&
    isFetching
  ) {
    return <Loader />
  }

  return (
    <AuthContext.Provider value={userData?.[0] ?? null}>
      <div className={themeClass}>
        <Main />
      </div>
    </AuthContext.Provider>
  )
}
