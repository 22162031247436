import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import type { Control } from 'react-hook-form/dist/types/form'
import {
  requirementNok,
  requirementOk,
  requirementsList
} from './PasswordHint.css'

export const PasswordHint = ({
  password
}: { password?: string }): ReactElement => {
  if (password === undefined || password === '') {
    return <></>
  }
  return (
    <ul className={requirementsList}>
      <li
        className={clsx(
          String(password).match(/[a-z]/g) != null
            ? requirementOk
            : requirementNok
        )}
      >
        Un caractère minuscule
      </li>
      <li
        className={clsx(
          String(password).match(/[A-Z]/g) != null
            ? requirementOk
            : requirementNok
        )}
      >
        Un caractère majuscule
      </li>
      <li
        className={clsx(
          String(password).match(/[0-9]/g) != null
            ? requirementOk
            : requirementNok
        )}
      >
        Un chiffre
      </li>
      <li
        className={clsx(
          String(password).match(/[^A-Z0-9a-z]/g) != null
            ? requirementOk
            : requirementNok
        )}
      >
        Un caractère spécial
      </li>
      <li
        className={clsx(password.length > 7 ? requirementOk : requirementNok)}
      >
        8 caractères au minimum
      </li>
    </ul>
  )
}

export const PasswordHintInput = ({
  control
}: { control: Control<any> }): ReactElement => {
  const { password }: { password: string | undefined } = useWatch({ control })
  return <PasswordHint password={password} />
}
