import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import { themeClass } from '~/styles/theme.css'
import Logo from '../../../../img/logo-square.svg'
import { logo, placeholder, wrapper } from './GlobalMenu.css'
import { GlobalMenuItem } from './GlobalMenuItem'
import type { MenuItemPrimary } from './menu'

interface GlobalMenuProps {
  menu?: MenuItemPrimary[]
  currentSection?: string
  legacyUrl?: string | null
}

export const GlobalMenu = ({
  menu,
  currentSection,
  legacyUrl
}: GlobalMenuProps): ReactElement => {
  return (
    <nav className={clsx(themeClass, wrapper)}>
      {menu?.map((item) => (
        <GlobalMenuItem
          key={item.id}
          menuItem={item}
          currentSection={currentSection ?? null}
          legacyUrl={legacyUrl}
        />
      ))}
      {menu === undefined &&
        [...new Array(8)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: Shimmer cant have unique key
          <div className={placeholder} key={index}>
            <Shimmer width={35} height={35} />
          </div>
        ))}
      <img src={Logo} alt="" className={logo} />
    </nav>
  )
}
