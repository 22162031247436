import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import type React from 'react'
import { useRef } from 'react'
import { type PropsWithChildren, type ReactElement, useEffect } from 'react'
import { closeTrigger, dropdown } from './Downdown.css'

interface DropdownProps extends PropsWithChildren {
  isOpen: boolean
  dropdownRef?: React.RefObject<HTMLDivElement>
  className?: string
  showCloseTrigger?: boolean
  onclose: () => void
}

export const Dropdown = ({
  children,
  dropdownRef,
  className,
  isOpen,
  showCloseTrigger,
  onclose
}: DropdownProps): ReactElement => {
  const localDropdownRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = ({ target }: MouseEvent): void => {
      if (
        isOpen &&
        target instanceof Node &&
        localDropdownRef.current?.contains(target) !== true &&
        dropdownRef?.current?.contains(target) !== true
      ) {
        onclose()
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [dropdownRef, isOpen, onclose])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={clsx(dropdown, className)}
          ref={localDropdownRef}
        >
          {showCloseTrigger && (
            <span onClick={onclose} className={closeTrigger}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
