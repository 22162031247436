import {
  type UseQueryResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { fetchApiWithToken } from '../utils'

export interface ConfigResponse {
  name: string
  value: string | number | boolean | null
}

export const useConfig = ({
  name
}: { name: string }): UseQueryResult<ConfigResponse, Error> =>
  useQuery({
    queryKey: ['config', name],
    queryFn: async ({ signal }) =>
      await fetchApiWithToken<ConfigResponse[]>({
        method: 'get',
        url: `/v1/configuration?name=${name}`,
        signal
      }).then((value) => value[0]),
    staleTime: 1000 * 60 * 60 * 24
  })

export interface BrandInfosResponse {
  name: string
  slug: string
  searchEndpoint: string
  legacyUrl: string
  appUrl: string
  logo?: string | null
  logoHeader?: string | null
}

export const useBrandInfos = (): UseQueryResult<BrandInfosResponse, Error> =>
  useQuery({
    queryKey: ['brandInfos'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: '/v1/configuration/brand'
      }),
    staleTime: 1000 * 60 * 60 * 24
  })

export interface ConfigValue {
  name: string
  value: string | number | boolean | null
}

export const useConfigValues = (
  keys: string[]
): UseQueryResult<{ [key: string]: ConfigValue }, Error> => {
  const searchParams = new URLSearchParams()
  for (const key of keys) {
    searchParams.append('name[]', key)
  }
  return useQuery({
    queryKey: ['config', keys],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/configuration?${searchParams.toString()}`
      }),
    staleTime: 1000 * 60 * 60
  })
}
