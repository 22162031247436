import { AnimatePresence, motion } from 'framer-motion'
import React, { type ReactElement, useState } from 'react'
import { Toast, type ToastProps, ToastType } from './Toast'
import { toastStack } from './Toast.css'
import { getToaster } from './getToaster'

export const ToastStack = (): ReactElement => {
  const [toasts, setToasts] = useState<Array<ToastProps & { id: string }>>([])
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  getToaster().current = (
    title?: string,
    content?: string,
    type?: ToastType
  ) => {
    const id = Math.random().toString(36)
    setToasts([...toasts, { title, content, type, id }])
    if (timer !== undefined) {
      clearTimeout(timer)
    }
    setTimer(
      setTimeout(() => {
        setToasts([])
      }, 5000)
    )
    return true
  }
  return (
    <div className={toastStack}>
      <AnimatePresence>
        {toasts.map(({ title, content, type, id }, index) => (
          <motion.div
            key={id}
            initial={{ opacity: 0, x: -200, zIndex: 999, position: 'relative' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200, zIndex: 999, position: 'relative' }}
          >
            <Toast
              key={id}
              type={type ?? ToastType.Default}
              title={title ?? ''}
              content={content ?? ''}
              onClose={() =>
                setToasts(toasts.filter((toast) => toast.id !== id))
              }
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}
