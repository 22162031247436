import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'normalize.css'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import 'unfonts.css'
import 'vite/modulepreload-polyfill'
import { App } from '~/App'
import '~/App.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

if (import.meta.env.APP_ENV === 'prod') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.job-explorer\.io/],
    replaysSessionSampleRate: import.meta.env.DEV ? 100 : 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

const params = new URLSearchParams(window.location.search)
if (params.has('jwt')) {
  // Bridge from legacy app
  localStorage.setItem('token', params.get('jwt') ?? '')
  if (params.has('instance')) {
    localStorage.setItem('instance', params.get('instance') ?? '')
  }
  params.delete('jwt')
  params.delete('instance')
  window.location.href = `${window.location.pathname}?${params.toString()}`
} else {
  document.addEventListener('DOMContentLoaded', () => {
    const appElement = document.querySelector('#app')
    if (appElement !== null) {
      createRoot(appElement).render(
        <StrictMode>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StrictMode>
      )
    }
  })
}
