import type React from 'react'
import type { ReactElement } from 'react'
import { badge } from './Badge.css'

interface BadgeItemProps {
  title: string
  textColor: string
  backgroundColor: string
}

export const Badge = ({
  title,
  textColor,
  backgroundColor
}: BadgeItemProps): ReactElement => {
  return (
    <span
      className={badge}
      style={{ color: textColor, backgroundColor: backgroundColor }}
      title={title}
    >
      {title}
    </span>
  )
}
