import { type UseMutationResult, useMutation } from '@tanstack/react-query'
import { type ResponseError, fetchApi, fetchApiWithToken } from '../utils'

export interface LoginParams {
  instance: string
  email: string
  password: string
}

export interface LoginReponse {
  token?: string
  code?: number
  message?: string
}

export const login = async ({
  instance,
  email,
  password
}: LoginParams): Promise<LoginReponse> =>
  await fetchApi({
    method: 'post',
    url: '/v1/login_check',
    data: { email, password },
    instance
  })

export const useGetUserToken = (): UseMutationResult<
  { token: string },
  ResponseError,
  { id: string | null }
> =>
  useMutation({
    mutationFn: async ({ id }) =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users/${id ?? ''}/token`
      })
  })
