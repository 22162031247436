import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import React, {
  type ElementType,
  type PropsWithChildren,
  type ReactElement,
  useEffect,
  useState
} from 'react'
import { themeClass } from '~/styles/theme.css'
import {
  decorated,
  tooltipDirections,
  tooltipVariants,
  wrapper
} from './Tooltip.css'

type TooltipProps = {
  as?: ElementType
  direction?: 'left' | 'right' | 'top' | 'bottom'
  className?: string
  text: string
} & PropsWithChildren

export const Tooltip = ({
  direction = 'top',
  text,
  className,
  as: Cmp = 'div',
  children
}: TooltipProps): ReactElement => {
  const tooltipRef = React.useRef<HTMLDivElement | null>(null)
  const [show, setShow] = useState(false)
  const [timer, setTimer] = useState<number | null>(null)
  const [alignDirection, setAlignDirection] = useState<'left' | 'right' | null>(
    null
  )

  const onOpening = (): void => {
    setShow(true)
    if (timer !== null) {
      window.clearTimeout(timer)
    }
  }

  const onClosing = (): void => {
    setTimer(
      window.setTimeout(() => {
        setShow(false)
      }, 100)
    )
  }

  useEffect(() => {
    const handleResize = () => {
      if (!show) {
        setAlignDirection(null)
      }
      if (tooltipRef.current !== null && show) {
        const { x, width } = tooltipRef.current.getBoundingClientRect()
        if (x + width > window.innerWidth - 10) {
          setAlignDirection('right')
        } else if (x < 0) {
          setAlignDirection('left')
        } else {
          setAlignDirection(null)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [show])

  return (
    <AnimatePresence>
      <Cmp
        onMouseEnter={onOpening}
        onMouseLeave={onClosing}
        className={clsx(decorated, className)}
      >
        {children}
        {show && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={clsx(
              themeClass,
              wrapper,
              tooltipVariants[direction],
              alignDirection ? tooltipDirections[alignDirection] : null
            )}
            key="tooltip"
            ref={tooltipRef}
          >
            {text}
          </motion.div>
        )}
      </Cmp>
    </AnimatePresence>
  )
}
