import {
  CatchBoundary,
  Outlet,
  createRootRouteWithContext
} from '@tanstack/react-router'
import React, { Suspense } from 'react'
import type { UserInfosResponse } from '~/api/queries/user'
import { GlobalError } from '~/components/Global/GlobalError'
import { ToastStack } from '~/components/Global/Interactions/Toast/ToastStack'

interface RouterContext {
  currentUser?: UserInfosResponse | null
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      )

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent
})

function RootComponent(): React.ReactElement {
  return (
    <>
      <CatchBoundary errorComponent={GlobalError} getResetKey={() => 'reset'}>
        <Outlet />
        <ToastStack />
      </CatchBoundary>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  )
}
