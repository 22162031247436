import {
  type UseQueryResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import clsx from 'clsx'
import React, {
  type PropsWithChildren,
  type ReactElement,
  useRef,
  useState
} from 'react'
import { useGetUserToken } from '~/api/mutations/login'
import { useBrandInfos } from '~/api/queries/global'
import { type UserInfosResponse, useMeInfos } from '~/api/queries/user'
import { fetchApiWithToken } from '~/api/utils'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import { container } from '~/components/Global/Interactions/Dropdown/Downdown.css'
import { Dropdown } from '~/components/Global/Interactions/Dropdown/Dropdown'
import {
  searchInput,
  switchBackButton,
  userItem,
  userList
} from './UserSwitcher.css'

type UserSwitcherProps = PropsWithChildren<{
  classname?: string
}>

export const UserSwitcher = ({
  classname,
  children
}: UserSwitcherProps): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const { data } = useBrandInfos()
  const legacyUrl = data?.legacyUrl ?? ''
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const {
    data: users,
    isLoading: isUsersLoading
  }: UseQueryResult<UserInfosResponse[], Error> = useQuery({
    queryKey: ['users'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: '/v1/users'
      }),
    refetchOnWindowFocus: false,
    staleTime: 1800_000,
    enabled: isOpen
  })
  const queryClient = useQueryClient()
  const getUserToken = useGetUserToken()
  const userInfos = useMeInfos()
  const [search, setSearch] = useState<string>('')

  const switchUser = async (
    user: UserInfosResponse
  ): Promise<{ token: string }> => {
    setOpen(false)
    return await getUserToken.mutateAsync(
      { id: String(user.id) },
      {
        onSuccess: async ({ token }) => {
          localStorage.setItem('mainToken', localStorage.getItem('token') ?? '')
          localStorage.setItem(
            'mainUser',
            `${userInfos?.firstname ?? ''} ${userInfos?.lastname ?? ''}`
          )
          localStorage.setItem('mainId', `${userInfos?.id ?? ''}`)
          localStorage.setItem('token', token)
          localStorage.setItem('id', String(user.id))
          localStorage.removeItem('currentGroup')
          void queryClient.invalidateQueries({ queryKey: ['user'] })
          // Handle legacy change user modale
          await fetch(`${legacyUrl ?? ''}/admin/users/swap_user/${user.id}`)
        }
      }
    )
  }

  const switchBack = async (): Promise<void> => {
    setOpen(false)
    localStorage.setItem('token', localStorage.getItem('mainToken') ?? '')
    localStorage.setItem('id', localStorage.getItem('mainId') ?? '')
    localStorage.removeItem('mainToken')
    localStorage.removeItem('mainUser')
    localStorage.removeItem('mainId')
    localStorage.removeItem('currentGroup')
    void queryClient.invalidateQueries({ queryKey: ['user'] })
    // Handle legacy change user modale
    await fetch(
      `${legacyUrl ?? ''}/admin/users/swap_user/${
        localStorage.getItem('mainId') ?? ''
      }`
    )
  }

  if (getUserToken.isPending) {
    return <Loader small={true} />
  }

  return (
    <>
      {localStorage.getItem('mainToken') !== null && (
        <span onClick={switchBack} className={switchBackButton}>
          Retour vers {localStorage.getItem('mainUser')}
        </span>
      )}
      {userInfos?.isSuperUser === true && (
        <div className={clsx(classname, container)} ref={dropdownRef}>
          <span
            onClick={() => {
              setOpen(!isOpen)
            }}
          >
            {children}
          </span>
          <Dropdown
            isOpen={isOpen}
            onclose={() => setOpen(false)}
            dropdownRef={dropdownRef}
          >
            {isUsersLoading && <Loader small={true} />}
            {users !== undefined && (
              <>
                <input
                  type="search"
                  className={searchInput}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Recherche"
                />
                <div className={userList}>
                  {users
                    .filter(
                      (user) =>
                        user.firstname
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        user.lastname
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    )
                    .map((user) => (
                      <div
                        key={user.id}
                        className={userItem}
                        onClick={async () => await switchUser(user)}
                      >
                        {user.firstname} {user.lastname}
                      </div>
                    ))}
                </div>
              </>
            )}
          </Dropdown>
        </div>
      )}
    </>
  )
}
