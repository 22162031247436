import { faChartPie } from '@fortawesome/pro-light-svg-icons'
import {
  faBell,
  faBellRing,
  faMessage,
  faUserGraduate,
  faXmark
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import type React from 'react'
import {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  useDeleteNotificationMutation,
  useReadNotificationMutation
} from '~/api/mutations/user'
import { useBrandInfos } from '~/api/queries/global'
import {
  type UserNotification,
  useCurrentUserNotifications
} from '~/api/queries/user'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import { container } from '~/components/Global/Interactions/Dropdown/Downdown.css'
import { Dropdown } from '~/components/Global/Interactions/Dropdown/Dropdown'
import {
  toolBarItemAttention,
  toolBarItemAttentionCount,
  toolBatItemAttentionIcon
} from '~/components/Layout/Header/Header.css'
import {
  notificationDropdown,
  notificationIcon,
  notificationIconVariants,
  notificationIconWrapper,
  notificationItem,
  notificationItemContent,
  notificationItemDate,
  notificationItemDeleteTrigger,
  notificationItemDeleteTriggerIcon,
  notificationItemDeleteWrapper,
  notificationItemSubtitle,
  notificationItemTitle,
  notificationItemTitleUnread,
  notificationItemUnreadDate,
  notificationStack,
  notificationTick,
  notificationUnreadTick,
  notificationsPlaceholder,
  notificationsTitle,
  notificationsTitleCount
} from '~/components/Layout/Header/NotificationViewer.css'
import { formatRelativeTime } from '~/utils'
import { AuthContext } from '~/utils/contexts'

const Notification = ({
  notification,
  userId,
  setCount
}: {
  notification: UserNotification
  userId: number
  setCount: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { data } = useBrandInfos()
  const [isRead, setIsRead] = useState<boolean>(notification.isRead)
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const readMutation = useReadNotificationMutation({
    userId
  })
  const deleteMutation = useDeleteNotificationMutation({
    userId
  })
  const legacyUrl = data?.legacyUrl ?? ''
  let title = ''
  let subtitle = ''
  let icon = null
  let onClick = () => {}
  switch (notification.type) {
    case 'application':
      title = `${notification.metadata.details.offerName}`
      subtitle = `Il y a ${notification.metadata.count} nouvelles candidatures sur l'offre`
      if (notification.metadata.count === 1) {
        subtitle = `${notification.metadata.stack[0]?.applicantName} a postulé sur l'offre`
      }
      onClick = () => {
        window.location.href = `${legacyUrl}/admin/jobmail/index/0/${notification.metadata.details.offerId}`
      }
      icon = faUserGraduate
      break

    case 'sms_reply':
      title = `SMS de ${notification.metadata.stack[0]?.applicantName}`
      subtitle = `${notification.metadata.stack[0]?.content}`
      if (notification.metadata.count > 1) {
        title = `${notification.metadata.count} SMS de ${notification.metadata.stack[0]?.applicantName}`
      }
      onClick = () => {
        window.location.href = `${legacyUrl}/admin/sms/thread/${notification.metadata.details.threadId}/`
      }
      icon = faMessage
      break

      case 'stats_file':
        title = `Rapport ${notification.metadata.stack[0]?.name?.toLowerCase() ?? ''} disponible`
        subtitle = `Statistiques ${notification.metadata.stack[0]?.period ?? ''}`
        onClick = () => {
          window.location.href = `${legacyUrl}/admin/statistiques/config`
        }
        icon = faChartPie
        break
  }
  const onNotifClick = () => {
    if (!isRead) {
      setIsRead(true)
      setCount((count) => count - 1)
      readMutation.mutate({ notificationId: notification.id })
    }
    onClick()
  }

  return (
    <motion.div
      variants={{
        initial: { opacity: 0, x: -20 },
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 }
      }}
      style={{ willChange: 'auto' }}
      className={notificationItem}
      onMouseEnter={() => {
        if (!isRead) {
          setTimeoutId(
            window.setTimeout(() => {
              setIsRead(true)
              setCount((count) => count - 1)
              readMutation.mutate({ notificationId: notification.id })
            }, 1000)
          )
        }
      }}
      onMouseLeave={() => {
        if (timeoutId) {
          window.clearTimeout(timeoutId)
        }
      }}
    >
      <div className={notificationIconWrapper} onClick={onNotifClick}>
        {icon && (
          <span
            className={clsx(
              notificationIcon,
              notificationIconVariants[notification.type]
            )}
          >
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </div>
      <div onClick={onNotifClick} className={notificationItemContent}>
        <div
          className={clsx(
            notificationItemTitle,
            isRead ? null : notificationItemTitleUnread
          )}
        >
          {title}
        </div>
        <div className={notificationItemSubtitle}>{subtitle}</div>
      </div>
      <div className={notificationItemDeleteWrapper}>
        <div
          className={clsx(
            notificationItemDate,
            isRead ? null : notificationItemUnreadDate
          )}
        >
          {formatRelativeTime(new Date(notification.updatedAt))}
        </div>
        <div className={notificationItemDeleteTrigger}>
          <FontAwesomeIcon
            icon={faXmark}
            onClick={(e) => {
              e.stopPropagation()
              if (!isRead) {
                setCount((count) => count - 1)
              }
              deleteMutation.mutate({ notificationId: notification.id })
            }}
            className={notificationItemDeleteTriggerIcon}
          />
        </div>
      </div>
      <div className={notificationTick}>
        {!isRead ? <span className={notificationUnreadTick} /> : null}
      </div>
    </motion.div>
  )
}

type NotificationsViewerProps = PropsWithChildren<{
  classname?: string
  currentUserId?: number
  defaultCount?: number
}>

export const NotificationsViewer = ({
  classname,
  currentUserId,
  defaultCount
}: NotificationsViewerProps): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(defaultCount ?? 0)
  const lastItemRef = useRef<HTMLDivElement | null>(null)
  const currentUser = useContext(AuthContext)
  const triggerRef = useRef<HTMLDivElement>(null)
  const userId = Number(currentUser?.id ?? currentUserId)
  const {
    userNotifications,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useCurrentUserNotifications({
    userId,
    active: isOpen
  })

  const endOfListReached = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          endOfListReached()
        }
      },
      { threshold: 0.5 }
    )
    if (lastItemRef.current) {
      observer.observe(lastItemRef.current)
    }
    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current)
      }
    }
  }, [endOfListReached])

  return (
    <>
      <div
        className={clsx(
          classname,
          container,
          count > 0 ? toolBarItemAttention : null
        )}
        ref={triggerRef}
      >
        <span
          onClick={() => {
            setOpen(!isOpen)
          }}
        >
          {count > 0 && (
            <span className={toolBarItemAttentionCount}>{count}</span>
          )}
          <FontAwesomeIcon
            icon={count > 0 ? faBellRing : faBell}
            className={count > 0 ? toolBatItemAttentionIcon : undefined}
          />
        </span>
      </div>
      <Dropdown
        isOpen={isOpen}
        onclose={() => setOpen(false)}
        showCloseTrigger={true}
        dropdownRef={triggerRef}
        className={notificationDropdown}
      >
        <div className={notificationsTitle}>
          Notifications{' '}
          <strong className={notificationsTitleCount}>
            ({Math.max(count, 0)})
          </strong>
        </div>
        {userNotifications?.length === 0 && (
          <div className={notificationsPlaceholder}>Aucune notification</div>
        )}
        <AnimatePresence>
          <motion.div
            className={notificationStack}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
            }}
            initial="hidden"
            animate="visible"
            style={{ willChange: 'auto' }}
          >
            {(userNotifications ?? []).map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                userId={userId}
                setCount={setCount}
              />
            ))}
          </motion.div>
        </AnimatePresence>
        {isLoading && <Loader small={true} />}
        <div ref={lastItemRef} />
      </Dropdown>
    </>
  )
}
