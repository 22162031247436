import React, { type ReactElement } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import z from 'zod'
import { Form, Submit, TextInput } from '~/components/Form'
import { GlobalAlert } from '~/components/Form/GlobalAlert'

const schema = z.object({
  instance: z.string().min(1, { message: 'Une instance est requise' }),
  email: z
    .string()
    .min(1, { message: 'Veuillez indiquer une adresse email' })
    .email({ message: "L'adresse e-mail doit être valide" }),
  password: z.string().min(1, { message: 'Veuillez indiquer un mot de passe' })
})

type LoginParams = z.infer<typeof schema>

export const Login = ({
  onSubmit,
  error,
  defaultValues = {}
}: {
  onSubmit: SubmitHandler<LoginParams>
  error?: string | null
  defaultValues?: object
}): ReactElement => {
  return (
    <Form
      onSubmit={onSubmit}
      schema={schema}
      globalError={error}
      defaultValues={defaultValues}
    >
      <TextInput label="Instance" name="instance" required autoFocus />
      <TextInput
        label="Email"
        name="email"
        type="email"
        autoComplete="username"
        required
      />
      <TextInput
        label="Mot de passe"
        name="password"
        type="password"
        autoComplete="current-password"
        required
      />
      <GlobalAlert />
      <Submit
        fullWidth={true}
        onSuccessReset={false}
        loadingText="Connexion en cours..."
        successText="Connecté !"
      >
        Se connecter
      </Submit>
    </Form>
  )
}
