import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { themeClass } from '~/styles/theme.css'
import {
  loaderText,
  picto,
  pictoSmall,
  wrapper,
  wrapperSmall
} from './Loader.css'
import loaderPicto from './loader.svg'

export const Loader = ({
  small = false
}: { small?: boolean }): ReactElement => {
  return (
    <div className={clsx(themeClass, wrapper, small && wrapperSmall)}>
      <img
        src={String(loaderPicto)}
        alt="Chargement en cours"
        className={clsx(picto, small ? pictoSmall : null)}
      />
      {!small && <div className={loaderText}>Chargement en cours</div>}
    </div>
  )
}
