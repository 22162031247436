import React, { type ReactElement, useRef, useState } from 'react'
import { Confirm } from './Confirm.jsx'
import { getConfirmPromptRef } from './confirmPrompt'

export interface ConfirmProps {
  title?: string
  content?: string
  textConfirm?: string
}

export const ConfirmGlobal = (): ReactElement => {
  const [show, setShow] = useState<boolean>(false)
  const [props, setProps] = useState<ConfirmProps>({
    title: '',
    content: '',
    textConfirm: ''
  })
  const resolveRef = useRef((v: boolean) => {})
  getConfirmPromptRef().current = async (
    title?: string,
    content?: string,
    textConfirm?: string
  ) =>
    await new Promise((resolve) => {
      setProps({ title, content, textConfirm })
      setShow(true)
      resolveRef.current = resolve
    })
  return (
    <Confirm
      onConfirm={() => {
        resolveRef.current(true)
        setShow(false)
      }}
      onCancel={() => {
        resolveRef.current(false)
        setShow(false)
      }}
      show={show}
      {...props}
    />
  )
}
