import type React from 'react'
import type { ReactElement } from 'react'
import { globalError, globalErrorSpacer } from './Form.css'

interface GlobalAlertProps {
  error?: string
}

export const GlobalAlert: React.FC<GlobalAlertProps> = ({
  error
}): ReactElement => {
  if (error !== undefined && error !== '') {
    return (
      <div role="alert" className={globalError}>
        {error}
      </div>
    )
  }
  return <div className={globalErrorSpacer} />
}
