import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import {
  aside,
  asideImage,
  asideTitle,
  container,
  main,
  mainImage
} from '~/pages/styles/Login.css'
import { themeClass } from '~/styles/theme.css'
import logoUrl from '/job-explorer.svg?url'
import loginDrawUrl from '/login.svg?url'

export const GlobalError = ({ error }: { error?: Error }): ReactElement => {
  return (
    <div className={clsx(themeClass, container)}>
      <main className={main}>
        <a href={'/'}>
          <h1 className={mainImage}>
            <img src={String(logoUrl)} alt="job explorer" />
          </h1>
        </a>
        <h1>Erreur {error !== undefined ? 'interne' : 404}</h1>
        <p>Une erreur est survenue lors de votre navigation :/</p>
        <div>
          <small>{error?.toString()}</small>
        </div>
      </main>
      <aside className={aside}>
        <h2 className={asideTitle}>
          Accompagner la croissance des professionnels du recrutement
        </h2>
        <img
          src={String(loginDrawUrl)}
          alt="Logo de job explorer"
          className={asideImage}
        />
      </aside>
    </div>
  )
}
