import React, { type ReactElement } from 'react'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import {
  globalWrapper,
  headerWrapper,
  menuWrapper,
  pageWrapper
} from '~/pages/styles/Dashboard.css'
import { Footer } from './Footer/Footer'
import { GlobalMenu } from './GlobalMenu/GlobalMenu'
import { Header } from './Header/Header'

export const DashboardShimmerLayout = (): ReactElement | null => {
  return (
    <>
      <div className={headerWrapper}>
        <Header />
      </div>
      <div className={globalWrapper}>
        <div className={menuWrapper}>
          <GlobalMenu />
        </div>
        <main className={pageWrapper}>
          <Loader />
        </main>
      </div>
      <Footer />
    </>
  )
}
