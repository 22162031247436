import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faChartKanban,
  faChartPie,
  faCirclePlus,
  faFileInvoice,
  faFileUpload,
  faGaugeHigh,
  faIndustry,
  faPaperPlaneTop,
  faShuffle,
  faUser,
  faUserGraduate
} from '@fortawesome/pro-light-svg-icons'

export interface MenuItem {
  id: string
  url?: string
  v3?: boolean
  isBeta?: boolean
  label: string
  icon?: IconDefinition
}

export type MenuItemPrimary = MenuItem & {
  submenu?: MenuItem[]
}

export interface AclsStructure {
  dashboard: boolean
  applicants: {
    create: boolean
    search: boolean
    import: boolean
    sms: boolean
  }
  reporting: boolean
}

export const getMenu = (acls?: AclsStructure): MenuItemPrimary[] => {
  const userAcls = acls ?? {
    dashboard: true,
    applicants: {
      create: true,
      search: true,
      import: true,
      sms: true
    },
    reporting: false
  }
  return [
    {
      id: 'dashboard',
      url: '/admin/accueil/dashboard',
      label: 'Dashboard',
      icon: faGaugeHigh
    },
    ...(userAcls.applicants.create ||
    userAcls.applicants.search ||
    userAcls.applicants.import ||
    userAcls.applicants.sms
      ? [
          {
            id: 'applicants',
            label: 'Candidats',
            url: userAcls.applicants.search
              ? '/admin/candidats/search'
              : undefined,
            icon: faUser
          }
        ]
      : []),
    {
      id: 'companies',
      label: 'Entreprises',
      url: '/admin/contacts/search',
      icon: faIndustry
    },
    {
      id: 'orders',
      label: 'Commandes',
      url: '/admin/recherches/commande',
      icon: faFileInvoice,
      submenu: [
        {
          id: 'orders_search',
          label: 'Liste des commandes',
          url: '/admin/recherches/commande',
          icon: faFileInvoice
        },
        {
          id: 'orders_kanban',
          label: 'Vue Kanban',
          url: '/orders',
          icon: faChartKanban,
          v3: true,
          isBeta: true
        }
      ]
    },
    {
      id: 'offers',
      label: "Offres d'emploi",
      url: '/admin/offres/search',
      icon: faShuffle
    },
    {
      id: 'jobmail',
      url: '/admin/jobmail',
      label: 'Candidatures',
      icon: faUserGraduate
    },
    {
      id: 'push',
      label: 'Placement actif',
      url: '/admin/push/cv',
      icon: faPaperPlaneTop
    },
    {
      id: 'reporting',
      url: '/admin/statistiques/reporting',
      label: 'Reporting',
      icon: faChartPie
    },
    {
      id: 'create',
      label: 'Créer',
      url: '/admin/candidats/create',
      submenu: [
        ...(userAcls.applicants.create
          ? [
              {
                id: 'candidat_import',
                label: 'Import de CV',
                url: '/admin/candidats/upload',
                icon: faFileUpload
              },
              {
                id: 'candidat',
                url: '/admin/candidats/create',
                label: 'Candidat',
                icon: faUser
              }
            ]
          : []),
        {
          id: 'entreprise',
          url: '/admin/entreprises/create',
          label: 'Entreprise',
          icon: faIndustry
        },
        {
          id: 'commande',
          url: '/admin/commandes/create_assign',
          label: 'Commande',
          icon: faFileInvoice
        },
        {
          id: 'offre',
          url: '/admin/offres/saisie_offres',
          label: "Offre d'emploi",
          icon: faShuffle
        },
        {
          id: 'push_offre',
          url: '/admin/push/offres',
          label: 'Push Offre',
          icon: faPaperPlaneTop
        }
      ],
      icon: faCirclePlus
    }
  ]
}
