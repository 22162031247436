import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { Modal, type modalProps } from '~/components/Global/Design/Modal/Modal'
import { themeClass } from '~/styles/theme.css'
import {
  buttonGroup,
  closeWrapper,
  content as contentStyle,
  inner,
  titleIcon,
  title as titleStyle
} from './Modal.css'

type StandardModalProps = modalProps & {
  title?: string | ReactElement
  icon?: IconProp
  buttons?: ReactElement[]
}

export const StandardModal = ({
  children,
  onClose,
  title,
  icon,
  buttons,
  ...rest
}: StandardModalProps): ReactElement => {
  return (
    <Modal onClose={onClose} {...rest}>
      <div className={clsx(themeClass, inner)}>
        <div className={closeWrapper} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {title && (
          <div className={titleStyle}>
            {icon && <FontAwesomeIcon icon={icon} className={titleIcon} />}
            {title}
          </div>
        )}
        <div className={contentStyle}>{children}</div>
        {buttons && <div className={buttonGroup}>{buttons}</div>}
      </div>
    </Modal>
  )
}
